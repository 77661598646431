/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
// import '../css/jquery-ui-1.8.20.custom.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

import flowplayer from './flowplayer-3.1.4.min';
import $ from 'jquery';
global.$ = $;
import 'jquery-ui';

$(document).ready(function() {
    $('#cookie_accept').click(function(e) {
        e.preventDefault();
        $(this).parent().parent().slideUp('slow');
        document.cookie = 'cookies_accepted=true;expires=Thu, 31 Dec 2099 12:34:23 GMT';
    });
});
